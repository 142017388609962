<template>
  <div class="iv2-news-item__line col-12">
    <router-link
      :to="{
        name: 'newsDetail',
        params: { id: content.id },
        query: Object.assign({}, $route.query),
      }"
      class="iv2-news-item__link"
    >
      <div class="row clearfix">
        <div
          class="iv2-news-item__date col-xl-2 col-lg-2 col-md-3 col-6 text-xl-center"
        >
          {{ content.opened_at }}
        </div>
        <div class="col-xl-2 col-lg-3 col-md-9 col-6">
          <small class="iv2-news-item__category">{{
            content.category_name
          }}</small>
        </div>
        <div class="iv2-news-item__title col-xl-8 col-lg-7 col-md-12">
          {{ content.title }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
.iv2-news-item__line {
  padding: 35px 0;
  border-top: 1px solid #bcbcbc;

  &:last-of-type {
    border-bottom: 1px solid #bcbcbc;
  }

  .iv2-news-item__link {
    .iv2-news-item__date {
      font-size: 1rem;
      font-weight: bold;
    }

    .iv2-news-item__category {
      padding: 0 10px;
      font-weight: bold;
      text-align: center;
      border: 1px solid;
      border-radius: 30px;
      display: inline-block;
    }

    .iv2-news-item__title {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-news-item__wrap {
    padding: 25px 0;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-news-item__line {
    padding: 25px 0;

    .iv2-news-item__link {
      .iv2-news-item__date {
        font-size: 10px;
      }
      .iv2-news-item__category {
        font-size: 10px;
      }
      .iv2-news-item__title {
        font-size: 14px;
      }
    }
  }
}
</style>
