<template>
  <div class="iv2-news-item__tile">
    <router-link
      :to="{
        name: 'newsDetail',
        params: { id: content.id },
        query: Object.assign({}, $route.query),
      }"
      class="iv2-news-item__link"
    >
      <div class="row">
        <div class="iv2-news-item__img fit-img col-md-4">
          <picture>
            <source
              type="image/webp"
              :srcset="
                content.thumbnail + $store.state.publicLang.general.image_webp
              "
            />
            <img
              class="m-0"
              :src="content.thumbnail"
              :alt="
                '「' + content.title + '」' + $store.state.publicLang.news.alt
              "
            />
          </picture>
        </div>
        <div class="iv2-news-item__list col-md-8 col-12 p-0 px-md-5">
          <p v-if="content.opened_at" class="iv2-news-item__date d-md-none">
            {{ content.opened_at }}
          </p>
          <div class="d-flex">
            <p
              v-if="content.opened_at"
              class="iv2-news-item__date d-none d-md-block"
            >
              {{ content.opened_at }}
            </p>
            <p class="iv2-news-item__label size-14">
              {{ content.category_name }}
            </p>
            <p
              v-if="!content.days_excess"
              class="iv2-news-item__new ml-1 size-14"
            >
              {{ $store.state.themeLang.general.new_label }}
            </p>
          </div>
          <h3 class="iv2-news-item__title my-xl-4">
            {{ content.title }}
          </h3>
          <p class="iv2-news-item__summary">{{ content.content }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
.iv2-news-item__tile {
  .iv2-news-item__link {
    display: block;
    text-decoration: none;

    &:hover .iv2-news-item__img {
      opacity: 0.7;
    }

    .iv2-news-item__img {
      height: 250px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .iv2-news-item__date {
      font-size: 24px;
      line-height: 1;
      margin-right: 20px;
      padding-top: 3px;
    }

    .iv2-news-item__label {
      text-align: center;
      border: 1px solid;
      border-radius: 30px;
      line-height: 1;
      padding: 6px 40px;
      background-color: #fff;
      font-weight: bold;
    }

    .iv2-news-item__new {
      color: #ed4343;
      font-weight: bold;
      padding: 7px 10px;
      line-height: 1;
    }

    .iv2-news-item__title {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}

@media (min-width: 1200px) {
  .iv2-news-item__tile {
    .iv2-news-item__list {
      padding: 0 100px;
    }
  }
}

@media (max-width: 1199px) {
  .iv2-news-item__tile {
    .iv2-news-item__link {
      .iv2-news-item__date {
        font-size: 16px;
        padding-top: 7px;
      }

      .iv2-news-item__label {
        padding: 6px 20px;
      }

      .iv2-news-item__title {
        font-size: 1.1rem;
        margin-top: 10px;
      }
    }
  }
}
</style>
