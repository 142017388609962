<template>
  <div class="iv2-news-card">
    <router-link
      :to="{
        name: content.type + 'Detail',
        params: { id: content.id },
        query: Object.assign({}, $route.query),
      }"
      class="iv2-news-card__link"
    >
      <div class="iv2-news-card__card theme__main-bg">
        <div class="iv2-news-card__img fit-img">
          <picture>
            <source
              type="image/webp"
              :srcset="
                content.thumbnail + $store.state.publicLang.general.image_webp
              "
            />
            <img
              class="m-0"
              :src="content.thumbnail"
              :alt="
                '「' + content.title + '」' + $store.state.publicLang.news.alt
              "
            />
          </picture>
          <span
            v-if="!content.days_excess"
            class="iv2-news-card__new theme__main-label"
            >{{ $store.state.themeLang.general.new_label }}</span
          >
        </div>

        <div class="iv2-news-card__body p-3 p-md-4">
          <div class="mt-2 mb-2 mb-md-4">
            <span class="iv2-news-card__label iv2-news__badge">
              {{ content.category_name }}
            </span>
          </div>

          <h3 class="iv2-news-card__title mb-0">
            {{ content.title }}
          </h3>
          <hr class="p-0 my-3" />
          <p v-if="content.opened_at" class="iv2-news-card__date m-0">
            {{ content.opened_at }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
.iv2-news-card {
  .iv2-news-card__link {
    display: block;
    text-decoration: none;

    .iv2-news-card__card {
      border-radius: 15px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

      &:hover {
        .iv2-news-card__img {
          opacity: 0.7;
        }
      }

      .iv2-news-card__img {
        height: 240px;
        transition: 0.3s;
        position: relative;

        img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .iv2-news-card__title {
        font-size: 1.1rem;
        font-weight: bold;
      }

      .iv2-news-card__new {
        font-size: 14px;
        font-weight: 600;
        padding: 1px 25px;
        position: absolute;
        left: 0;
        bottom: -15px;
      }

      .iv2-news-card__date {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 767px) {
  .iv2-news-card {
    .iv2-news-card__link {
      .iv2-news-card__card {
        .iv2-news-card__img {
          height: 116px;
        }

        .iv2-news-card__new {
          font-size: 10px;
          padding: 1px 15px;
        }

        .iv2-news-card__label {
          padding: 0 20px;
          margin-bottom: 10px;
          font-size: 10px;
        }

        .iv2-news-card__date {
          font-size: 10px;
        }

        .iv2-news-card__title {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
